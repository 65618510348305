import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/succeeded.css";
import validated from "../img/validated.png";
import girlInTheCar from "../img/girlInTheCar.png";

function Succeeded({
  email,
  firstname,
  name,
  Adress,
  city,
  zip,
  phone,
  brand,
  model,
  type,
  year,
  Kilometers,
  Fuel,
  price,
  registration,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  number_of_order,
  password,
  passwordConfirm,
  setPage,
  token,
  setRefreshing,
  setEmail,
  setFirstname,
  setName,
  setAdress,
  setCity,
  setZip,
  setBrand,
  setModel,
  setType,
  setYear,
  setKilometers,
  setFuel,
  setPrice,
  setRegistration,
  setPickUpDate,
  setSlotsAvailableBeginning,
  setSlotsAvailableEnding,
  setPhone,
  setPassword,
  setPasswordConfirm,
}) {
  const [requestSended, setRequestSended] = useState(false);
  // TODO: remove the idPartner value on the backend
  useEffect(() => {
    const createUser = async () => {
      setRequestSended(true);
      const response = await axios.post(`${process.env.REACT_APP_URL}/create/user`,
        {
          email,
          name,
          firstname,
          adress: Adress,
          city,
          zip,
          phone,
          registration,
          password: password,
          passwordConfirm: passwordConfirm,
        }
      );
      if (response.data) {
        const responseOrder = await axios.post(`${process.env.REACT_APP_URL}/create/order`,
          {
            userToken: response.data.user.token,
            number_of_order: number_of_order,
            pickUpDate,
            price,
            brand,
            model,
            type,
            year,
            kilometers: Kilometers,
            fuel: Fuel,
            registration,
            slotsAvailableBeginning:
              slotsAvailableBeginning === null ? 8 : slotsAvailableBeginning,
            slotsAvailableEnding:
              slotsAvailableEnding === null ? 18 : slotsAvailableEnding,
            pickUpAdress: `${Adress} ${zip} ${city}`,
            pickUpCity: city,
            dropOffAdress: `${Adress} ${zip} ${city}`,
          }
        );
        if (responseOrder) {
          await axios.post(`${process.env.REACT_APP_URL}/send/email`, {
            email: email,
            number_of_order: number_of_order,
            message:
              "Commande réalisé avec succès nous revenons vers vous au plus vite",
          });
        }
      }
    };

    const pushNewOrder = async () => {
      setRequestSended(true);
      const responseOrder = await axios.post(`${process.env.REACT_APP_URL}/create/order`,
        {
          userToken: token,
          number_of_order: number_of_order,
          pickUpDate,
          price,
          brand: brand,
          model: model,
          type: type,
          year: year,
          Kilometers: Kilometers,
          Fuel: Fuel,
          registration: registration,
          slotsAvailableBeginning:
            slotsAvailableBeginning === null ? 8 : slotsAvailableBeginning,
          slotsAvailableEnding:
            slotsAvailableEnding === null ? 18 : slotsAvailableEnding,
          pickUpAdress: `${Adress} ${zip} ${city}`,
          pickUpCity: city,
          dropOffAdress: `${Adress} ${zip} ${city}`,
        }
      );
      if (responseOrder) {
        await axios.post(`${process.env.REACT_APP_URL}/send/email`, {
          email: email,
          number_of_order: number_of_order,
          message:
            "Commande réalisé avec succès nous revenons vers vous au plus vite",
        });
      }
    };
    if (requestSended === false) {
      if (token === null) {
        createUser();
      } else {
        pushNewOrder();
      }
    }
  }, [
    email,
    number_of_order,
    name,
    firstname,
    Adress,
    city,
    zip,
    phone,
    brand,
    model,
    type,
    year,
    Kilometers,
    Fuel,
    registration,
    price,
    pickUpDate,
    slotsAvailableBeginning,
    slotsAvailableEnding,
    password,
    passwordConfirm,
    token,
    requestSended,
  ]);

  setTimeout(() => {
    setRefreshing(true);
    setEmail(null);
    setFirstname(null);
    setName(null);
    setAdress(null);
    setCity(null);
    setZip(null);
    setBrand(null);
    setModel(null);
    setType(null);
    setYear(null);
    setKilometers(null);
    setFuel(null);
    setPrice(128);
    setRegistration(null);
    setPickUpDate(null);
    setSlotsAvailableBeginning(null);
    setSlotsAvailableEnding(null);
    setPhone(null);
    setPassword(null);
    setPasswordConfirm(null);
    setPage("booking");
    setRequestSended(false);
  }, 10000);

  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth();
  const yearOfdate = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const dateToPickUp = new Date(pickUpDate);

  const dayToPickUp = dateToPickUp.getDate();
  const monthToPickUp = dateToPickUp.getMonth();
  const yearOfdateToPickUp = dateToPickUp.getFullYear();

  const monthArray = [
    "Janvier",
    "Février",
    "MArs",
    "Avrill",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  return (
    <div className="containerSuccededOrder">
      <div className="containerRecapOrder">
        <div className="blocInfosRecap">
          <div className="containerTitleInfosRecap">
            <div style={{ marginRight: 20 }}>
              <img src={validated} alt="logo" />
            </div>
            <div>
              <div className="titleInfosRecap">Paiement effectué</div>
              <div className="textInfosRecap">
                Le {day <= 9 ? `0${day}` : day} {monthArray[month]} {yearOfdate}{" "}
                à {hour <= 9 ? `0${hour}` : hour}h
                {minute <= 9 ? `0${minute}` : minute}
              </div>
              <div className="textInfosRecap">
                COMMANDE N° {number_of_order}
              </div>
            </div>
          </div>
          <div>
            Vous recevrez par mail les informations concernant votre demande de
            réservation pour :
          </div>
          <div className="textRecapBooking">
            le {dayToPickUp <= 9 ? `0${dayToPickUp}` : dayToPickUp}{" "}
            {monthArray[monthToPickUp]} {yearOfdateToPickUp} entre{" "}
            {slotsAvailableBeginning}h et {slotsAvailableEnding}h au {Adress} -{" "}
            {zip} {city}
          </div>
        </div>
      </div>
      <div>
        <img src={girlInTheCar} alt="girl" className="imgGirl" />
      </div>
    </div>
  );
}

export default Succeeded;
