import React from "react";
import "../styles/InfosOrder.css";
import union from "../img/Union.png";
function InfosOrder({
  Adress,
  zip,
  city,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  type,
  processNumber,
}) {
  const date = new Date(pickUpDate);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const monthTab = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const categorieTab = {
    car: "Citadine 89€",
    "4X4": "4x4 99€",
    carHybrid: "Véhicule électtrique 89€",
    van: "Utilitaire / Camping car 99€",
    gpl: "Véhicule au gaz 109€",
  };

  return (
    <div className="containerInfos">
      <div>
        <img src={union} className="search" alt="loupe" />
      </div>
      <div>
        <p className="textInfos">
          {Adress && Adress.toUpperCase()} {zip && zip}{" "}
          {city && city.toUpperCase()}
        </p>
        <p className="textInfos">
          {day} {monthTab[month]} {year} créneau {slotsAvailableBeginning}h -{" "}
          {slotsAvailableEnding}h
        </p>
        {processNumber > 2 && (
          <p className="textInfos">Type de véhicule: {categorieTab[type]}</p>
        )}
      </div>
    </div>
  );
}

export default InfosOrder;
