import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactStars from "react-stars";
import { Pagination } from "swiper";
import "../styles/Booking.css";
import useGAEventsTracker from "../hooks/useGAEventsTracker";

// CONTAINERS
import InfosVehicle from "./InfosVehicle";
import InfosUser from "./InfosUser";

// COMPONENTS
import InfosSlotsAvailable from "../components/InfosSlotsAvailable";

// IMAGES
import Logo from "../img/ClicknCT-couleurs.png";
import check from "../img/check.png";
import voiture from "../img/voiture-N.png";
import restitution from "../img/restitution-N.png";
import womenDriver from "../img/womenDriver.png";
import logoBootlid from "../img/bootlidLogo.png";
import bruno from "../img/bruno.png";
import alex from "../img/alex.png";
import joel from "../img/joel.png";
import logo from "../img/ClicknCT-couleurs2.png";
import facility from "../img/facilité.png";
import security from "../img/securité.png";
import time from "../img/temps.png";
import Phone from "../img/telephone.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import Comments from "../components/Comments";

function Booking({
  center,
  email,
  firstname,
  name,
  Adress,
  zip,
  city,
  phone,
  brand,
  model,
  year,
  Kilometers,
  Fuel,
  type,
  registration,
  pickUpDate,
  password,
  passwordConfirm,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  selected,
  setPage,
  setEmail,
  setFirstname,
  setName,
  setAdress,
  setZip,
  setCity,
  setPhone,
  setBrand,
  setModel,
  setPrice,
  setType,
  setYear,
  setKilometers,
  setFuel,
  setRegistration,
  setPickUpDate,
  setSlotsAvailableBeginning,
  setSlotsAvailableEnding,
  setPassword,
  setPasswordConfirm,
  setSelected,
  token,
  windowDimensions,
  partnerArround,
  setToken,
  processNumber,
  setProcessNumber,
}) {
  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const [errorPage1, setErrorPage1] = useState(false);
  const [errorPage2, setErrorPage2] = useState(false);
  const [errorPage3, setErrorPage3] = useState(false);
  const [activated, setActivated] = useState(false);
  const [activatedConfirm, setActivatedConfirm] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const GAEventTracker = useGAEventsTracker("Internal Links");
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setEmail(null);
  }

  useEffect(() => {
    if (email !== null && email !== "") {
      const timeoutAfter = setTimeout(async () => {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/check/user`,
          {
            email: email,
          }
        );
        if (response.data.userExist === true && token === null) {
          openModal();
          setToken(response.data.token);
        }
      }, 2000);
      return () => clearTimeout(timeoutAfter);
    }
  }, [email, token, setToken]);

  const handleApiLoaded = (map, maps) => {
    return {
      Animation: {
        center: map.center,
      },
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_TOP,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
    };
  };

  const handleNext = () => {
    if (processNumber === 1 && (Adress === null || zip === null)) {
      setErrorPage1(true);
    } else if (
      processNumber === 2 &&
      (brand === null ||
        model === null ||
        year === null ||
        Kilometers === null ||
        Fuel === null ||
        registration === null)
    ) {
      setErrorPage2(true);
    } else if (
      processNumber === 3 &&
      (email === null ||
        firstname === null ||
        name === null ||
        Adress === null ||
        zip === null ||
        phone === null ||
        password === null ||
        passwordConfirm === null)
    ) {
      setErrorPage3(true);
    } else {
      if (processNumber !== 3) {
        setProcessNumber(processNumber + 1);
        GAEventTracker("changement de page", `Page n° ${processNumber + 1}`);
        setErrorPage1(false);
        setErrorPage2(false);
        setErrorPage3(false);
      } else {
        if (password === passwordConfirm) {
          setPage("payment");
          GAEventTracker("changement de page", `Page de paiement`);
        } else {
          setErrorPage3(true);
          setErrorPassword(true);
        }
      }
    }
  };
  const priceTab = [
    {
      car: 128,
      "4x4": 138,
      van: 138,
      gpl: 148,
    },
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
  return (
    <div className="containerBooking">
      {/* PHONE NUMBER */}
      {windowDimensions.width >= 799 ? (
        <div className="phoneNumberBloc">
          <img src={Phone} alt="phone" className="phoneNumberImg" />
          <div className="phoneNumberText">01 48 45 48 42</div>
        </div>
      ) : (
        <a href="tel:+33148454842" className="phoneNumberBloc">
          <img src={Phone} alt="phone" className="phoneNumberImg" />
          <div className="phoneNumberText">01 48 45 48 42</div>
        </a>
      )}
      {/*END PHONE NUMBER */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img src={Logo} alt="logo" className="logoModal" />
        <h2>Cette adresse mail est déja utilisée!</h2>
        <div className="containerBtnModal">
          <button
            onClick={() => {
              closeModal();
              setToken(null);
            }}
            className="btnModal"
          >
            Essayer une autre adresse mail
          </button>
          <button onClick={closeModal} className="btnModal">
            Continuer avec cette meme adresse
          </button>
        </div>
      </Modal>

      {processNumber === 1 && (
        <>
          <video
            autoPlay
            controls={false}
            muted
            loop
            style={{
              width: "100%",
            }}
          >
            <source
              src="https://res.cloudinary.com/bruno-guyen/video/upload/v1659065319/1500x300_so7gps.mp4"
              type="video/mp4"
            />
          </video>
          <div className="blocInfos">
            <div className="boxInfos">
              <>
                <InfosSlotsAvailable
                  setProcessNumber={setProcessNumber}
                  selected={selected}
                  processNumber={processNumber}
                  errorPage1={errorPage1}
                  setAdress={setAdress}
                  setCity={setCity}
                  setZip={setZip}
                  Adress={Adress}
                  zip={zip}
                  city={city}
                  pickUpDate={pickUpDate}
                  setPickUpDate={setPickUpDate}
                  setSlotsAvailableBeginning={setSlotsAvailableBeginning}
                  setSlotsAvailableEnding={setSlotsAvailableEnding}
                  setSelected={setSelected}
                  handleNext={handleNext}
                />
              </>
            </div>

            {/* PUB SCROLlABLE */}
            <div
              className={
                processNumber === 1 ? "containerPubBetween" : "containerHidden"
              }
            >
              <div className="titlePubInfos">Découvrez Click & CT</div>
              {windowDimensions.width <= 799 ? (
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="blocBoxPubScrollable"
                  spaceBetween={0}
                  slidesPerView={1}
                >
                  <SwiperSlide className="containerPubInfos">
                    <div style={{ marginLeft: 10 }}>
                      <div>
                        <img src={check} alt="checked" />
                      </div>
                      <h1>La réservation</h1>
                      <div className="textExplanation">
                        Je réserve un créneau en ligne sur{" "}
                        <strong>CLICKNCT</strong> et je renseigne les
                        informations de mon véhicule.
                        <div>Je règle directement en ligne.</div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="containerPubInfos">
                    <div style={{ marginLeft: 10 }}>
                      <div>
                        <img src={voiture} alt="voiture" />
                      </div>
                      <h1>Le convoyage</h1>
                      <p className="textExplanation">
                        Je confie mon véhicule à un convoyeur{" "}
                        <strong>Bootlid</strong>.
                        <p>
                          Il déposera votre véhicule au contrôle technique le
                          plus proche.
                        </p>
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="containerPubInfos">
                    <div style={{ marginLeft: 10 }}>
                      <div>
                        <img src={restitution} alt="voiture" />
                      </div>
                      <h1>La restitution</h1>
                      <p className="textExplanation">
                        Une fois le contrôle technique effectué, le convoyeur
                        dépose mon véhicule et les documents en{" "}
                        <strong>main propre</strong>.
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              ) : (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div className="containerPubInfos">
                    <div>
                      <img src={check} alt="checked" />
                      <h1>La réservation</h1>
                    </div>
                    <p className="textExplanation">
                      Je réserve un créneau en ligne sur{" "}
                      <strong>CLICKNCT</strong> et je renseigne les informations
                      de mon véhicule.
                      <p>Je règle directement en ligne.</p>
                    </p>
                  </div>
                  <div className="containerPubInfos">
                    <div>
                      <img src={voiture} alt="voiture" />
                      <h1>Le convoyage</h1>
                    </div>
                    <p className="textExplanation">
                      Je confie mon véhicule à un convoyeur{" "}
                      <strong>Bootlid</strong>.
                      <p>
                        Il déposera votre véhicule au contrôle technique le plus
                        proche.
                      </p>
                    </p>
                  </div>
                  <div className="containerPubInfos">
                    <div>
                      <img src={restitution} alt="voiture" />
                      <h1>La restitution</h1>
                    </div>
                    <p className="textExplanation">
                      Je confie mon véhicule à un convoyeur une fois le contrôle
                      technique effectué, le convoyeur dépose mon véhicule et
                      les documents en <strong>main propre</strong>.
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* MAP */}

            <h2 className="titlePubInfos">CLICK&CT près de chez vous </h2>
            <div className="boxMaps">
              <div className="containerMpasExplanation">
                <div className="titleMapsExplanation">
                  CLICK&CT travaille avec un panel de contrôles techniques près
                  de chez vous.
                </div>
                <div className="textMapsExplanation">
                  Votre véhicule est pris en charge et emmené au contrôle
                  technique le plus proche afin d'optimiser le temps d’attente.
                </div>
              </div>
              {center !== undefined && (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapApiKey }}
                  defaultCenter={center}
                  defaultZoom={11}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                >
                  {partnerArround &&
                    partnerArround.CenterFinded.map((item, index) => {
                      return (
                        <i
                          className="fas fa-map-marker-alt"
                          lat={item.partner.account.location.lat}
                          lng={item.partner.account.location.lng}
                          key={index}
                        ></i>
                      );
                    })}
                </GoogleMapReact>
              )}
            </div>

            <div className="containerBootlid">
              <img
                src={womenDriver}
                alt="voiture"
                style={{
                  height: 300,
                  width: "100%",
                  boxSizing: "content-box",
                  position: "absolute",
                  zIndex: -10,
                }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="containerHeaderBootlid">
                  <div>
                    <div className="titleBootlidInfos">
                      NOTRE PARTENAIRE BOOTLID
                    </div>
                    <p className="bootlidExplanation">
                      BOOTLID l'agence de convoyage de confiance
                    </p>
                  </div>
                  <div className="boxBtnBootlid">
                    <a
                      className="btnBootlid"
                      href="https://www.bootlid.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Découvrir
                    </a>
                    <img
                      src={logoBootlid}
                      style={{ height: 25 }}
                      alt="logoBootlid"
                    />
                  </div>
                </div>
              </div>
              {/* STARS AND VOTES */}
              <div className="containerVoteCustomer">
                <div className="titleVote">Votre avis compte pour nous !</div>
                <div className="voteWithStars">
                  <div>
                    <img src={logo} alt="logo" style={{ height: 60 }} />
                  </div>
                  <div className="blocStars">
                    <div className="nameVote">CLICK&CT</div>
                    <div className="starsAndNote">
                      <ReactStars
                        count={5}
                        value={4.75}
                        size={24}
                        edit={false}
                        color2={"#ffd700"}
                      />
                      <div className="note">4.75</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {/* REPLACE IT BY AVIS'S MAP */}
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="containerStarsAndNote"
                  spaceBetween={windowDimensions.width <= 799 ? 1 : 50}
                  slidesPerView={windowDimensions.width <= 799 ? 1 : 3}
                >
                  <SwiperSlide>
                    <Comments
                      firstname={"Julie"}
                      text={
                        "Un service de qualité, réservation facile et rapide, je travaillais pendant qu’il s’occupait du CT de ma voiture."
                      }
                      note={5}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Comments
                      firstname={"Romain"}
                      text={
                        "Un service premium, simplicité, efficacité et professionnalisme. La géolocalisation est top"
                      }
                      note={5}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Comments
                      firstname={"Amelie"}
                      text={
                        "Un chauffeur très pro et efficace tout s’est bien passé merci encore"
                      }
                      note={4}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Comments
                      firstname={"Farid"}
                      text={
                        "Facile et rapide, tout s’est déroulé sans soucis. Merci pour le temps que j’ai gagné"
                      }
                      note={5}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Comments
                      firstname={"François"}
                      text={
                        "Quel bonheur et le gain de temps pour moi. Réservation en ligne rapide service top mon CT fait en moins de 2h, vous êtes au top"
                      }
                      note={5}
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <Comments
                      firstname={"Julien"}
                      text={
                        "Le contrôle technique de ma voiture s’est déroulé sans soucis. La voiture est intacte. Merci !"
                      }
                      note={4.75}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="containerWhoAreWe">
              <div className="titleWhoAreWe">Qui sommes-nous ?</div>
              <div className="textWhoAreWe">
                CLICK&CT est une plateforme née de l’expertise de trois jeunes
                collaborateurs. Nous avons élaboré une solution vous permettant
                de gagner du temps pour vos contrôles techniques. Notre priorité
                ? Vous garantir un service premium.
              </div>
              <div className="associeted">
                <div className="boxAssocieted">
                  <img src={bruno} style={{ width: "100%" }} alt="associés" />
                  <div className="headbandAssocieted">
                    <div className="headbandOpacity" />
                    <div className="nameAssocieted">Bruno GUYEN</div>
                    <div>Président</div>
                  </div>
                </div>
                <div className="boxAssocieted">
                  <img src={alex} style={{ width: "100%" }} alt="associés" />
                  <div className="headbandAssocieted">
                    <div className="headbandOpacity" />
                    <div className="nameAssocieted">Alex CASTANHO</div>
                    <div>Directeur Général</div>
                  </div>
                </div>
                <div className="boxAssocieted">
                  <img src={joel} style={{ width: "100%" }} alt="associés" />
                  <div className="headbandAssocieted">
                    <div className="headbandOpacity" />
                    <div className="nameAssocieted">Joel PHATTHANASINH</div>
                    <div>Directeur Logistique</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerFooter">
              <div className="titleFooter">Pourquoi passer par CLICK&CT ?</div>
              <div className="boxIconFooter">
                <div className="inconFooter">
                  <div className="arroundIcon">
                    <img src={facility} alt="icons" />
                  </div>
                  <div className="textIcon">Facilité</div>
                </div>
                <div className="inconFooter">
                  <div className="arroundIcon">
                    <img src={time} alt="icons" />
                  </div>
                  <div className="textIcon">Gain de temps</div>
                </div>
                <div className="inconFooter">
                  <div className="arroundIcon">
                    <img src={security} alt="icons" />
                  </div>
                  <div className="textIcon">Sécurité</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {processNumber === 2 && (
        <InfosVehicle
          errorPage2={errorPage2}
          brand={brand}
          setBrand={setBrand}
          model={model}
          setModel={setModel}
          setType={setType}
          priceTab={priceTab}
          year={year}
          setYear={setYear}
          Kilometers={Kilometers}
          setKilometers={setKilometers}
          Fuel={Fuel}
          setFuel={setFuel}
          registration={registration}
          setRegistration={setRegistration}
          setPrice={setPrice}
          processNumber={processNumber}
          setProcessNumber={setProcessNumber}
          Adress={Adress}
          zip={zip}
          city={city}
          pickUpDate={pickUpDate}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          type={type}
          handleNext={handleNext}
        />
      )}
      {processNumber === 3 && (
        <InfosUser
          processNumber={processNumber}
          setProcessNumber={setProcessNumber}
          errorPage3={errorPage3}
          email={email}
          firstname={firstname}
          name={name}
          phone={phone}
          password={password}
          setPassword={setPassword}
          activated={activated}
          activatedConfirm={activatedConfirm}
          passwordConfirm={passwordConfirm}
          errorPassword={errorPassword}
          setEmail={setEmail}
          setFirstname={setFirstname}
          setName={setName}
          setAdress={setAdress}
          setPhone={setPhone}
          setErrorPassword={setErrorPassword}
          setActivated={setActivated}
          setActivatedConfirm={setActivatedConfirm}
          setPasswordConfirm={setPasswordConfirm}
          Adress={Adress}
          zip={zip}
          city={city}
          pickUpDate={pickUpDate}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          type={type}
          handleNext={handleNext}
        />
      )}
    </div>
  );
}

export default Booking;
