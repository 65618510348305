import React from "react";
function BtnValidate({ title, handleNext, processNumber }) {
  return (
    <div>
      {processNumber > 1 ? (
        <div className="containerBtnValidate">
          <button className="btnValidate" onClick={handleNext}>
            <div>{title}</div>
          </button>
        </div>
      ) : (
        <button className="btnValidateBlue" onClick={handleNext}>
          <div className="textBtnValidate">{title}</div>
        </button>
      )}
    </div>
  );
}

export default BtnValidate;
