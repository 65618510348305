import React from "react";
import "../styles/InfosVehicle.css";
// IMAGES
import girl from "../img/girl.png";
// COMPONENTS
import InfosOrder from "../components/InfosOrder.js";
import BtnValidate from "../components/BtnValidate";
// ICONS
import user from "../img/compte.png";

function InfosVehicle({
  infosUserIsOpen,
  data,
  processNumber,
  setProcessNumber,
  errorPage3,
  errorPassword,
  email,
  firstname,
  name,
  phone,
  password,
  setPassword,
  activated,
  activatedConfirm,
  passwordConfirm,
  setEmail,
  setFirstname,
  setName,
  setPhone,
  setActivated,
  setActivatedConfirm,
  setPasswordConfirm,
  Adress,
  zip,
  city,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  type,
  handleNext,
}) {
  return (
    <div className="containerInfosVehicle">
      <div className="containerInfosForInfosVehicle">
        {!infosUserIsOpen && (
          <InfosOrder
            Adress={Adress}
            zip={zip}
            city={city}
            pickUpDate={pickUpDate}
            slotsAvailableBeginning={slotsAvailableBeginning}
            slotsAvailableEnding={slotsAvailableEnding}
            type={type}
            processNumber={processNumber}
          />
        )}
        {infosUserIsOpen !== true && (
          <div className="displayReturn">
            {!infosUserIsOpen && (
              <i
                className="fas fa-angle-left"
                onClick={(e) => {
                  e.preventDefault();
                  setProcessNumber(processNumber - 1);
                }}
              />
            )}
            <img src={user} alt="user" />
          </div>
        )}

        <div className="titleInfosForInfosVehicle">
          {!infosUserIsOpen ? "Création de compte" : "Modification du compte"}
        </div>

        <input
          placeholder={!data ? "Nom" : data.account.name}
          className={
            errorPage3 && name === null ? "errorBorder" : " inputDivInfos"
          }
          value={name !== null ? name : null}
          type="text"
          onChange={(e) => {
            e.preventDefault();
            setName(e.target.value);
          }}
        />
        <input
          placeholder={!data ? "Prénom" : data.account.firstname}
          className={
            errorPage3 && firstname === null ? "errorBorder" : " inputDivInfos"
          }
          value={firstname !== null ? firstname : null}
          type="text"
          onChange={(e) => {
            e.preventDefault();
            setFirstname(e.target.value);
          }}
        />
        <input
          placeholder={!data ? "Téléphone" : data.account.phone}
          className={
            errorPage3 && phone === null ? "errorBorder" : " inputDivInfos"
          }
          value={phone !== null ? phone : null}
          type="tel"
          onChange={(e) => {
            e.preventDefault();
            setPhone(e.target.value);
          }}
        />
        <input
          placeholder={!data ? "E-mail" : data.email}
          className={
            errorPage3 && email === null ? "errorBorder" : " inputDivInfos"
          }
          value={email !== null ? email : null}
          type="email"
          onChange={(e) => {
            e.preventDefault();
            setEmail(e.target.value);
          }}
        />
        <div className="passwordWithEye">
          <input
            placeholder={!password ? "Mot de passe" : password}
            className={
              errorPage3 && password === null ? "errorBorder" : " inputDivInfos"
            }
            value={password !== null ? password : null}
            type={!activated ? "password" : "text"}
            onChange={(e) => {
              e.preventDefault();
              setPassword(e.target.value);
            }}
          />
          <div>
            {!activated ? (
              <i
                className="fas fa-eye blackIcon"
                onClick={() => setActivated(!activated)}
              />
            ) : (
              <i
                class="fas fa-eye-slash blackIcon"
                onClick={() => setActivated(!activated)}
              />
            )}
          </div>
        </div>

        <div className="passwordWithEye">
          <input
            placeholder={
              !passwordConfirm
                ? " Confirmer votre mot de passe"
                : passwordConfirm
            }
            className={
              errorPage3 && passwordConfirm === null
                ? "errorBorder"
                : " inputDivInfos"
            }
            value={passwordConfirm !== null ? passwordConfirm : null}
            type={!activatedConfirm ? "password" : "text"}
            onChange={(e) => {
              e.preventDefault();
              setPasswordConfirm(e.target.value);
            }}
          />

          <div>
            {!activatedConfirm ? (
              <i
                className="fas fa-eye blackIcon"
                onClick={() => setActivatedConfirm(!activatedConfirm)}
              />
            ) : (
              <i
                class="fas fa-eye-slash blackIcon"
                onClick={() => setActivatedConfirm(!activatedConfirm)}
              />
            )}
          </div>
        </div>
        {errorPassword && (
          <div className="errorPassword">
            Votre mot de passe et la confirmation de votre mot de passe ne sont
            pas similaires
          </div>
        )}
        <div className="containerCheckbox">
          <input
            type="checkbox"
            onChange={(e) => console.log("e", e.target.checked)}
          />
          <div>
            J’accepte de donner mes informations à Click & CT et quelle soit
            gardée selon les conditions générales de la CNIL
          </div>
        </div>

        {!infosUserIsOpen && (
          <div className="blocBtnContinu">
            <BtnValidate
              title={"Continuer"}
              handleNext={handleNext}
              processNumber={processNumber}
            />
          </div>
        )}
      </div>

      <div>
        <img src={girl} alt="girl" className="imgInfosVehicle" />
      </div>
    </div>
  );
}

export default InfosVehicle;
