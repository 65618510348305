import React from "react";
import "../styles/InfosVehicle.css";
import mercedes from "../img/mercedes.png";
import InfosOrder from "../components/InfosOrder.js";
import car from "../img/voiture-V3.png";
import BtnValidate from "../components/BtnValidate";
function InfosVehicle({
  data,
  errorPage2,
  brand,
  setBrand,
  model,
  setModel,
  setType,
  priceTab,
  year,
  setYear,
  Kilometers,
  setKilometers,
  Fuel,
  setFuel,
  registration,
  setRegistration,
  setPrice,
  setProcessNumber,
  processNumber,
  infosVehicleIsOpen,
  Adress,
  zip,
  city,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  type,
  handleNext,
}) {
  const handleChangeRegistration = (e) => {
    e.preventDefault();
    setRegistration(e.target.value.toUpperCase());
    let string = e.target.value;
    for (let i = 0; i < string.length; i++) {
      if (string.length === 2 || string.length === 6) {
        setRegistration(e.target.value.toUpperCase() + "-");
      }
    }
  };

  return (
    <div className="containerInfosVehicle">
      <div className="containerInfosForInfosVehicle">
        {infosVehicleIsOpen !== true && (
          <InfosOrder
            Adress={Adress}
            zip={zip}
            city={city}
            pickUpDate={pickUpDate}
            slotsAvailableBeginning={slotsAvailableBeginning}
            slotsAvailableEnding={slotsAvailableEnding}
            type={type}
            processNumber={processNumber}
          />
        )}
        {infosVehicleIsOpen !== true && (
          <div className="displayReturn">
            {!infosVehicleIsOpen && (
              <i
                className="fas fa-angle-left"
                onClick={(e) => {
                  e.preventDefault();
                  setProcessNumber(processNumber - 1);
                }}
              />
            )}
            <img src={car} alt="car" />
          </div>
        )}

        <div className="titleInfosForInfosVehicle">Type de véhicule:</div>

        <input
          placeholder={!data ? "Marque de véhicule" : data.vehicles[0].brand}
          className={
            errorPage2 && brand === null ? "errorBorder" : " inputDivInfos"
          }
          value={brand !== null ? brand : null}
          type="text"
          onChange={(e) => {
            e.preventDefault();
            setBrand(e.target.value);
          }}
        />
        <input
          placeholder={!data ? "Modèle" : data.vehicles[0].model}
          className={
            errorPage2 && model === null ? "errorBorder" : " inputDivInfos"
          }
          value={model !== null ? model : null}
          type="text"
          onChange={(e) => {
            e.preventDefault();
            setModel(e.target.value);
          }}
        />
        <select
          className="selectDivInfos"
          defaultValue={data && data.vehicles[0].type}
          onChange={(e) => {
            e.preventDefault();
            setType(e.target.value);
            if (e.target.value === "car") {
              setPrice(priceTab[0].car);
            } else if (e.target.value === "4x4") {
              setPrice(priceTab[0]["4x4"]);
            } else if (e.target.value === "van") {
              setPrice(priceTab[0].van);
            } else if (e.target.value === "gpl") {
              setPrice(priceTab[0].gpl);
            }
          }}
        >
          <option value="car">Voiture hors 4X4 89€</option>
          <option value="4x4">4x4 99€</option>
          <option value="carHybrid">Hybrid ou électrique 89€</option>
          <option value="van">Utilitaire / Camping car 99€</option>
          <option value="gpl">Véhicule au Gaz 109€</option>
        </select>
        <input
          placeholder={!data ? "Année" : data.vehicles[0].year}
          className={
            errorPage2 && year === null ? "errorBorder" : " inputDivInfos"
          }
          value={year !== null ? year : null}
          type="number"
          onChange={(e) => {
            e.preventDefault();
            setYear(e.target.value);
          }}
        />
        <input
          placeholder={!data ? "Kilométrage" : data.vehicles[0].kilometers}
          className={
            errorPage2 && Kilometers === null ? "errorBorder" : " inputDivInfos"
          }
          value={Kilometers !== null ? Kilometers : null}
          type="number"
          onChange={(e) => {
            e.preventDefault();
            setKilometers(e.target.value);
          }}
        />
        <select
          className={
            errorPage2 && Fuel === null ? "errorBorder" : " selectDivInfos"
          }
          defaultValue={data && data.vehicles[0].fuel}
          onChange={(e) => {
            e.preventDefault();
            setFuel(e.target.value);
          }}
        >
          <option value="Essence">Essence</option>
          <option value="Diesel">Diesel</option>
          <option value="Gpl">Gpl</option>
          <option value="Électricité">Électricité</option>
        </select>
        <input
          placeholder={
            !data ? "Immatriculation" : data.vehicles[0].registration
          }
          className={
            errorPage2 && registration === null
              ? "errorBorder"
              : " inputDivInfos"
          }
          value={registration !== null ? registration : null}
          type="text"
          onChange={(e) => handleChangeRegistration(e)}
        />
        {infosVehicleIsOpen !== true && (
          <div className="blocBtnContinu">
            <BtnValidate
              title={"Continuer"}
              handleNext={handleNext}
              processNumber={processNumber}
            />
          </div>
        )}
      </div>
      <div>
        <img src={mercedes} alt="car" className="imgInfosVehicle" />
      </div>
    </div>
  );
}

export default InfosVehicle;
