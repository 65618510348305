import React from "react";
import "../styles/Header.css";
import logo from "../img/ClicknCT-couleurs2.png";
import Title from "../img/ClicknCT-DECLI-2.svg";

function Header({ setPage, setProcessNumber }) {
  return (
    <div className="headerSmallScreen">
      <img
        src={logo}
        alt="logo"
        className="logoRound"
        onClick={() => {
          setProcessNumber(1);
          setPage("booking");
        }}
      />
      <img src={Title} alt="logo" className="titleRound" />
      <div className="profilForLargeDevice">PROFIL</div>
      <div className="iconHeader">
        <i className="fas fa-user" onClick={() => setPage("profil")} />
      </div>
    </div>
  );
}

export default Header;
