import React, { useState } from "react";
import "../styles/Payment.css";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import reading from "../img/reading.png";
// ICONS
import calendar from "../img/calendar.png";
import time from "../img/time.png";
import voiture from "../img/voiture-V3.png";
import localisation from "../img/localisation.png";
import euro from "../img/euro.png";
import card from "../img/card.png";

const Checkout = ({
  email,
  firstname,
  name,
  Adress,
  city,
  zip,
  setPage,
  price,
  number_of_order,
  type,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  setProcessNumber,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cgvValidated, setCgvValidated] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    // On récupère ici les données bancaires que l'utilisateur rentre

    const cardNumber = elements.getElement(CardNumberElement);
    setMessage(`En cours de paiement ...`);
    // Une fois le token reçu depuis l'API Stripe
    // Requête vers notre serveur
    // On envoie le token reçu depuis l'API Stripe
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/payment`,
        {
          amount: price * 100,
          description: `Commande n°${number_of_order}`,
          city: city,
          adress: Adress,
          zip: zip,
          email: email,
          name: name,
        }
      );
      // On confirme le paiement pour terminer la procedure
      const { paymentIntent } = await stripe.confirmCardPayment(
        response.data.clientSecret,
        {
          payment_method: {
            card: cardNumber,
          },
        }
      );
      // Si la réponse du serveur est favorable, la transaction a eu lieu

      if (paymentIntent.status === "succeeded") {
        setMessage(`Paiement accepté ...`);
        setPage("succeeded");
      } else {
        setMessage(`Paiement refusé ...`);
        setPage("denied");
      }
    } catch (error) {
      setMessage(null);
      setErrorMessage(
        "Une erreur est survenue..., vérifiez les infos de la carte"
      );
    }
  };

  const cardOption = {
    hidePostalCode: true,
    style: {
      base: {
        color: "black",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": {
          color: "light-gray",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "red",
        iconColor: "red",
      },
    },
  };

  const date = new Date(pickUpDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return (
    <div className="containerPayment">
      <div className="form">
        <div className="return">
          <i
            className="fas fa-angle-left"
            onClick={(e) => {
              e.preventDefault();
              setProcessNumber(3);
              setPage("booking");
            }}
          />
          <div className="textReturn">Retour</div>
        </div>
        <div className="containerRecap">
          <div className="titleCheckout">Récapitulatif</div>
          <div className="blocInfosCheckout">
            <div className="containerImgCheckout">
              <img src={calendar} alt="calendar" className="imgCheckout" />
            </div>
            <div className="textCardCheckout">
              {day <= 9 ? `0${day}` : day}/{month <= 9 ? `0${month}` : month}/
              {year}
            </div>
          </div>
          <div className="blocInfosCheckout">
            <div className="containerImgCheckout">
              <img src={time} alt="time" className="imgCheckout" />
            </div>
            <div className="textCardCheckout">
              entre {slotsAvailableBeginning}h et {slotsAvailableEnding}h
            </div>
          </div>
          <div className="blocInfosCheckout">
            <div className="containerImgCheckout">
              <img
                src={localisation}
                alt="localisation"
                className="imgCheckout"
              />
            </div>
            <div className="textCardCheckout">
              {Adress} {zip} {city}
            </div>
          </div>
          <div className="blocInfosCheckout">
            <div className="containerImgCheckout">
              <img src={voiture} alt="voiture" className="imgCheckout" />
            </div>
            <div className="textCardCheckout">
              {type === "car" && "Citadine"}
              {type === "4x4" && "4x4"}
              {type === "carHybrid" && "Hybrid ou électrique"}
              {type === "van" && "Utilitaire / Camping car"}
              {type === "gpl" && "Véhicule au Gaz"}
            </div>
          </div>

          <div className="blocInfosCheckout">
            <div className="containerImgCheckout">
              <img src={euro} alt="euro" />
            </div>
            <div>
              <div className="textCardPrice">
                <div>Prix CT:</div>
                <div className="price">{price - 39}€</div>
              </div>
              <div className="textCardPrice">
                <div>Convoyage: </div>
                <div className="price">39€</div>
              </div>
              <div className="textCardPrice">
                <div>Total TTC: </div>
                <div className="price">{price}€</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="displayFlex">
            <img src={card} alt="credit card" />
            <div className="titleCard"> Paiement</div>
          </div>

          <div
            className="borderInfosCard"
            onChangeCapture={(e) => console.log("e->", e)}
          >
            <CardNumberElement options={cardOption} />
          </div>
          <div className="boxInfosDateCard">
            <div className="borderInfosCardDate">
              <CardExpiryElement options={cardOption} />
            </div>
            <div className="borderInfosCardDate">
              <CardCvcElement options={cardOption} />
            </div>
          </div>

          <div className="nameOnCard">
            <div className="name">{firstname}</div>
            <div className="name">{name}</div>
          </div>
        </div>
        <div className="ContainerCguCgv">
          <input
            type="checkbox"
            onChange={(e) => setCgvValidated(e.target.checked)}
          />
          <label for="cgv"></label>
          <div>
            En cliquant je reconnais avoir lu et accepte les
            <a
              href={"https://snazzy-rabanadas-b2f019.netlify.app"}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              CGV/CGU{" "}
            </a>
            de Click&Ct
          </div>
        </div>
        <div className="containerPrice">
          {cgvValidated && price >= 89 ? (
            <div
              type="submit"
              onClick={handleSubmit}
              disabled={!stripe || !elements}
              className="btnPaymentValidated"
            >
              PAYER {price} EUROS
            </div>
          ) : (
            <div
              onClick={() =>
                setErrorMessage("Veuillez lire et accepter les conditions ")
              }
              disabled={!stripe || !elements}
              className="btnPaymentNotValidated"
            >
              PAYER {price} EUROS
            </div>
          )}
          {errorMessage === null && message !== null && (
            <div className="message">{message}</div>
          )}
          {message === null && errorMessage !== null && (
            <div className="errorMessagePayment">{errorMessage}</div>
          )}
        </div>
      </div>
      <img src={reading} alt="reader" className="imgGirl" />
    </div>
  );
};

export default Checkout;
