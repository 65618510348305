import "./styles/App.css";
import "./styles/Reset.css";
import { useEffect, useState } from "react";
import Profil from "./containers/Profil";
import Booking from "./containers/Booking";
import Payment from "./containers/Payment";
import Header from "./components/Header";
import Succeeded from "./containers/Succeeded";
import Cookies from "js-cookie";
import axios from "axios";

import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

function App() {
  const [center, setCenter] = useState();
  const [page, setPage] = useState("booking");
  const [email, setEmail] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [name, setName] = useState(null);
  const [Adress, setAdress] = useState(null);
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [type, setType] = useState("car");
  const [year, setYear] = useState(null);
  const [Kilometers, setKilometers] = useState(null);
  const [Fuel, setFuel] = useState("Essence");
  const [registration, setRegistration] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [price, setPrice] = useState(128);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [slotsAvailableBeginning, setSlotsAvailableBeginning] = useState(null);
  const [slotsAvailableEnding, setSlotsAvailableEnding] = useState(null);
  const [number_of_order, setNumber_of_order] = useState(null);
  const [selected, setSelected] = useState(0);
  const [dataReturned, setDataReturned] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [partnerArround, setPartnerArround] = useState(null);
  const [token, setToken] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [processNumber, setProcessNumber] = useState(1);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    Cookies.remove("token");
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // CHECK IF THE USER IS CONNECTED
  useEffect(() => {
    if (dataReturned === null) {
      setToken(null);
    } else {
      setToken(dataReturned.token);
      Cookies.set("token", dataReturned.token, { expires: 1 });
    }
  }, [dataReturned]);
  console.log("<<<<<token>>>>>>>", token);
  // CHECK IF THE USER IS ALREADY CONNECTED
  useEffect(() => {
    const fetchData = async () => {
      if (token !== (undefined || null)) {
        const dataReturned = await axios.post(
          `${process.env.REACT_APP_URL}/read/user`,
          {
            token: token,
          }
        );
        if (dataReturned.data) {
          setDataReturned(dataReturned.data.user);
        }
      }
    };
    fetchData();
  }, [token]);

  // ID ORDER
  useEffect(() => {
    function randomStr(len, arr) {
      var ans = "";
      for (let i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
      }
      return ans;
    }
    setNumber_of_order(randomStr(9, "1234567890"));
    setRefreshing(false);
  }, [refreshing]);

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

    navigator.geolocation.getCurrentPosition(function (position) {
      if (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });
  }, []);
  const initialViewState = {
    lat: 48.859047,
    lng: 2.3567828,
  };

  // FEATURE FOR SEE ALL PARTNER'S ARROUND CUSTOMER
  useEffect(() => {
    const fetchPartnerArround = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/search/close`,
        {
          adressToClose: "avenue des champs elysées 75008 Paris",
        }
      );
      setPartnerArround(response.data);
    };
    fetchPartnerArround();
  }, []);

  const handleNextAlreadyConnected = (data) => {
    setEmail(data.email);
    setFirstname(data.account.firstname);
    setName(data.account.name);
    setPhone(data.account.phone);
    setAdress(data.account.adress);
    setZip(data.account.zip);
    setCity(data.account.city);
    setBrand(data.vehicles[0].brand);
    setModel(data.vehicles[0].model);
    setType(data.vehicles[0].type);
    setYear(data.vehicles[0].year);
    setKilometers(data.vehicles[0].kilometers);
    setFuel(data.vehicles[0].fuel);
    setRegistration(data.vehicles[0].registration);
    setPage("booking");
  };
  // USED FOR GOOGLE ANALYTICS TRACKER PAGE
  useEffect(() => {
    ReactGA.pageview(page);
  }, [page]);

  return (
    <div>
      <Header setPage={setPage} setProcessNumber={setProcessNumber} />
      {page === "profil" && (
        <Profil
          email={email}
          firstname={firstname}
          name={name}
          Adress={Adress}
          zip={zip}
          city={city}
          phone={phone}
          brand={brand}
          model={model}
          type={type}
          pickUpDate={pickUpDate}
          year={year}
          Kilometers={Kilometers}
          Fuel={Fuel}
          price={price}
          registration={registration}
          password={password}
          passwordConfirm={passwordConfirm}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          setPage={setPage}
          setEmail={setEmail}
          setFirstname={setFirstname}
          setName={setName}
          setAdress={setAdress}
          setCity={setCity}
          setZip={setZip}
          setBrand={setBrand}
          setModel={setModel}
          setType={setType}
          setYear={setYear}
          setKilometers={setKilometers}
          setFuel={setFuel}
          setPrice={setPrice}
          setRegistration={setRegistration}
          setPickUpDate={setPickUpDate}
          setSlotsAvailableBeginning={setSlotsAvailableBeginning}
          setSlotsAvailableEnding={setSlotsAvailableEnding}
          setPhone={setPhone}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
          selected={selected}
          setSelected={setSelected}
          handleNextAlreadyConnected={handleNextAlreadyConnected}
          token={token}
          dataReturned={dataReturned}
          setDataReturned={setDataReturned}
          windowDimensions={windowDimensions}
          setToken={setToken}
        />
      )}
      {page === "booking" && (
        <Booking
          center={center !== undefined ? center : initialViewState}
          email={email}
          firstname={firstname}
          name={name}
          Adress={Adress}
          zip={zip}
          city={city}
          phone={phone}
          brand={brand}
          model={model}
          type={type}
          pickUpDate={pickUpDate}
          year={year}
          Kilometers={Kilometers}
          Fuel={Fuel}
          price={price}
          registration={registration}
          initialViewState={initialViewState}
          password={password}
          passwordConfirm={passwordConfirm}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          setPage={setPage}
          setEmail={setEmail}
          setFirstname={setFirstname}
          setName={setName}
          setAdress={setAdress}
          setCity={setCity}
          setZip={setZip}
          setBrand={setBrand}
          setModel={setModel}
          setType={setType}
          setYear={setYear}
          setKilometers={setKilometers}
          setFuel={setFuel}
          setPrice={setPrice}
          setRegistration={setRegistration}
          setPickUpDate={setPickUpDate}
          setSlotsAvailableBeginning={setSlotsAvailableBeginning}
          setSlotsAvailableEnding={setSlotsAvailableEnding}
          setPhone={setPhone}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
          selected={selected}
          setSelected={setSelected}
          token={token}
          setToken={setToken}
          windowDimensions={windowDimensions}
          partnerArround={partnerArround}
          processNumber={processNumber}
          setProcessNumber={setProcessNumber}
        />
      )}
      {page === "payment" && (
        <Payment
          setPage={setPage}
          email={email}
          firstname={firstname}
          name={name}
          Adress={Adress}
          city={city}
          zip={zip}
          price={price}
          number_of_order={number_of_order}
          type={type}
          pickUpDate={pickUpDate}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          password={password}
          passwordConfirm={passwordConfirm}
          setProcessNumber={setProcessNumber}
        />
      )}
      {page === "succeeded" && (
        <Succeeded
          setPage={setPage}
          email={email}
          firstname={firstname}
          name={name}
          Adress={Adress}
          city={city}
          zip={zip}
          phone={phone}
          brand={brand}
          price={price}
          model={model}
          type={type}
          year={year}
          Kilometers={Kilometers}
          Fuel={Fuel}
          registration={registration}
          pickUpDate={pickUpDate}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          password={password}
          passwordConfirm={passwordConfirm}
          number_of_order={number_of_order}
          setEmail={setEmail}
          setFirstname={setFirstname}
          setName={setName}
          setAdress={setAdress}
          setCity={setCity}
          setZip={setZip}
          setBrand={setBrand}
          setModel={setModel}
          setType={setType}
          setYear={setYear}
          setKilometers={setKilometers}
          setFuel={setFuel}
          setPrice={setPrice}
          setRegistration={setRegistration}
          setPickUpDate={setPickUpDate}
          setSlotsAvailableBeginning={setSlotsAvailableBeginning}
          setSlotsAvailableEnding={setSlotsAvailableEnding}
          setPhone={setPhone}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
          token={token}
          setRefreshing={setRefreshing}
        />
      )}
    </div>
  );
}

export default App;
