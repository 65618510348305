import axios from "axios";
import React, { useState } from "react";
import "../styles/Profil.css";
import Account from "./Account";
import girlInTheCar from "../img/girlInTheCar.png";

function Profil({
  email,
  firstname,
  name,
  Adress,
  zip,
  city,
  phone,
  brand,
  model,
  type,
  pickUpDate,
  year,
  Kilometers,
  Fuel,
  price,
  registration,
  password,
  passwordConfirm,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  selected,
  setEmail,
  setPage,
  setFirstname,
  setName,
  setAdress,
  setCity,
  setZip,
  setBrand,
  setModel,
  setType,
  setYear,
  setKilometers,
  setFuel,
  setPrice,
  setRegistration,
  setPickUpDate,
  setSlotsAvailableBeginning,
  setSlotsAvailableEnding,
  setPhone,
  setPassword,
  setPasswordConfirm,
  setSelected,
  handleNextAlreadyConnected,
  dataReturned,
  setDataReturned,
  windowDimensions,
  setToken,
}) {
  const [activated, setActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/login`, {
        email: email,
        password: password,
      });
      if (response.data) {
        setDataReturned(response.data.user);
        setAdress(response.data.user.account.adress);
        setZip(response.data.user.account.zip);
        setCity(response.data.user.account.city);
      }
    } catch (error) {
      console.log({ error: error.message });
      setErrorMessage(
        "Mot de passe erroné ou compte introuvable, merci de vérifier votre email et/ou mot de passe"
      );
    }
  };
  return (
    <>
      {!dataReturned ? (
        <div div className="boxProfil">
          <div className="containerSuccededOrder">
            <div className="containerLogin">
              <div>
                <div className="containerLoginInput">
                  <div className="titleLogin">Se connecter</div>
                  <div className="">Email</div>
                  <input
                    placeholder="ex: .....@gmail.com"
                    className={
                      errorMessage && email === null
                        ? "errorBorder"
                        : "inputLogin"
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setEmail(e.target.value);
                      setErrorMessage(null);
                    }}
                  />
                </div>
                <div className="containerLoginInput">
                  <div className="">Password</div>
                  <div>
                    {!activated ? (
                      <i
                        className="fas fa-eye"
                        onClick={() => setActivated(!activated)}
                      />
                    ) : (
                      <i
                        class="fas fa-eye-slash"
                        onClick={() => setActivated(!activated)}
                      />
                    )}
                  </div>

                  <input
                    placeholder="*********"
                    className={
                      errorMessage && password === null
                        ? "errorBorder"
                        : "inputLogin"
                    }
                    type={!activated ? "password" : "text"}
                    onChange={(e) => {
                      e.preventDefault();
                      setPassword(e.target.value);
                      setErrorMessage(null);
                    }}
                  />
                </div>
                <div className="errorMessage">{errorMessage}</div>
                <div className="containerBtnSubmitLogin">
                  <button className="btnValidateBlue" onClick={handleSubmit}>
                    Se connecter
                  </button>
                </div>
              </div>
            </div>
            <div className="driver">
              <img src={girlInTheCar} alt="girl" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Account
            data={dataReturned && dataReturned}
            email={email}
            firstname={firstname}
            name={name}
            Adress={Adress}
            zip={zip}
            city={city}
            phone={phone}
            brand={brand}
            model={model}
            type={type}
            pickUpDate={pickUpDate}
            year={year}
            Kilometers={Kilometers}
            Fuel={Fuel}
            price={price}
            registration={registration}
            password={password}
            passwordConfirm={passwordConfirm}
            slotsAvailableBeginning={slotsAvailableBeginning}
            slotsAvailableEnding={slotsAvailableEnding}
            setPage={setPage}
            setEmail={setEmail}
            setFirstname={setFirstname}
            setName={setName}
            setAdress={setAdress}
            setCity={setCity}
            setZip={setZip}
            setBrand={setBrand}
            setModel={setModel}
            setType={setType}
            setYear={setYear}
            setKilometers={setKilometers}
            setFuel={setFuel}
            setPrice={setPrice}
            setRegistration={setRegistration}
            setPickUpDate={setPickUpDate}
            setSlotsAvailableBeginning={setSlotsAvailableBeginning}
            setSlotsAvailableEnding={setSlotsAvailableEnding}
            setPhone={setPhone}
            setPassword={setPassword}
            setPasswordConfirm={setPasswordConfirm}
            selected={selected}
            setSelected={setSelected}
            setDataReturned={setDataReturned}
            handleNextAlreadyConnected={handleNextAlreadyConnected}
            windowDimensions={windowDimensions}
            setToken={setToken}
          />
        </>
      )}
    </>
  );
}

export default Profil;
