import React from "react";
import ReactStars from "react-stars";
import "../styles/Comments.css";
function Comments({ firstname, text, note }) {
  return (
    <div className="containerComments">
      <div className="nameAndStars">
        <div className="nameComment">{firstname}</div>
        <ReactStars
          count={5}
          value={note}
          size={24}
          edit={false}
          color2={"#ffd700"}
        />
      </div>
      <div>{text}</div>
    </div>
  );
}

export default Comments;
