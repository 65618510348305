import React from "react";
import "../styles/Payment.css";
import "../styles/StatusMessage.css";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/Checkout";

import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const Payment = ({
  email,
  setPage,
  firstname,
  name,
  Adress,
  city,
  zip,
  price,
  number_of_order,
  type,
  pickUpDate,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  setProcessNumber,
}) => {
  return (
    <div className="container">
      <Elements stripe={stripePromise}>
        <CheckoutForm
          setPage={setPage}
          email={email}
          firstname={firstname}
          name={name}
          Adress={Adress}
          city={city}
          zip={zip}
          price={price}
          number_of_order={number_of_order}
          type={type}
          pickUpDate={pickUpDate}
          slotsAvailableBeginning={slotsAvailableBeginning}
          slotsAvailableEnding={slotsAvailableEnding}
          setProcessNumber={setProcessNumber}
        />
      </Elements>
    </div>
  );
};

export default Payment;
