import React, { useEffect } from "react";
import BtnValidate from "./BtnValidate";
function InfosSlotsAvailable({
  selected,
  Adress,
  setAdress,
  setCity,
  setZip,
  zip,
  pickUpDate,
  setPickUpDate,
  setSlotsAvailableBeginning,
  setSlotsAvailableEnding,
  setSelected,
  handleNext,
  infosResaIsOpen,
  data,
  city,
  errorPage1,
  errorPage2,
  errorPage3,
  errorPassword,
  processNumber,
}) {
  const today = new Date();
  today.setDate(today.getDate() + 3);
  const dayOfDate = today.getDate();
  const monthOfDate = today.getMonth() + 1;
  const yearOfDate = today.getFullYear();

  const dateReturnFromData = new Date(data && data.pickUpDate);
  const dayFromDataReturn = dateReturnFromData.getDate();
  const monthFromDataReturn = dateReturnFromData.getMonth() + 1;
  const yearFromDataReturn = dateReturnFromData.getFullYear();

  // FOR VERIFY IF THE DAY IS NOT IN WEEK-END
  useEffect(() => {
    const dateForResa = new Date(
      `${yearOfDate}-${monthOfDate <= 9 ? `0${monthOfDate}` : monthOfDate}-${
        dayOfDate <= 9 ? `0${dayOfDate}` : dayOfDate
      }`
    );
    // DAY + 3 OF TODAY FOR RESERVATION
    const numDayofWeek = dateForResa.getDay();

    // IF IS SATURDAY
    if (numDayofWeek === 6) {
      dateForResa.setDate(dateForResa.getDate() + 2);
      const dayForResa = dateForResa.getDate();
      const monthForResa = dateForResa.getMonth() + 1;
      const yearForResa = dateForResa.getFullYear();

      setPickUpDate(
        `${yearForResa}-${
          monthForResa <= 9 ? `0${monthForResa}` : monthForResa
        }-${dayForResa <= 9 ? `0${dayForResa}` : dayForResa}`
      );
      // IF IS SUNDAY
    } else if (numDayofWeek === 0) {
      dateForResa.setDate(dateForResa.getDate() + 1);
      const dayForResa = dateForResa.getDate();
      const monthForResa = dateForResa.getMonth() + 1;
      const yearForResa = dateForResa.getFullYear();

      setPickUpDate(
        `${yearForResa}-${
          monthForResa <= 9 ? `0${monthForResa}` : monthForResa
        }-${dayForResa <= 9 ? `0${dayForResa}` : dayForResa}`
      );
    } else {
      setPickUpDate(
        `${yearOfDate}-${monthOfDate <= 9 ? `0${monthOfDate}` : monthOfDate}-${
          dayOfDate <= 9 ? `0${dayOfDate}` : dayOfDate
        }`
      );
    }
  }, [dayOfDate, monthOfDate, setPickUpDate, yearOfDate]);

  const handleDate = (event) => {
    event.preventDefault();
    const day = new Date(event.target.value);
    const numDayofWeek = day.getDay();
    if (numDayofWeek === 6 || numDayofWeek === 0) {
      alert(
        "Veuillez sélectionner un jour en semaine, hors (Samedi / Dimanche)"
      );
    } else {
      setPickUpDate(event.target.value);
    }
  };
  return (
    <div className="AllContainerSlotAvailable">
      <div className="blocSoltAvailable">
        {infosResaIsOpen !== true && (
          <div className="titleDivInfos">Reserver un créneau </div>
        )}
        <div className="blocInfosReservation">
          <div className="containerDivInputAdress">
            {data === undefined ? (
              <input
                placeholder={"N° et Nom de la voie"}
                className={
                  errorPage1 && Adress === null
                    ? "errorBorderInfosDate1"
                    : " inputDivInfosDate1"
                }
                value={Adress}
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  setAdress(e.target.value);
                }}
              />
            ) : (
              // VALUE RETURN FROM DATA ALREADY SAVED
              <input
                placeholder={data && data.pickUpAdress}
                className={" inputDivInfosDate1"}
                value={Adress !== null && Adress}
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  setAdress(e.target.value);
                }}
              />
            )}
          </div>
          <div className="containerCityAndZip">
            <input
              placeholder="Code postal"
              className={
                errorPage1 && zip === null
                  ? "errorBorderInfosDate2"
                  : " inputDivInfosDate2"
              }
              value={zip !== null && zip}
              type="number"
              onChange={(e) => {
                e.preventDefault();
                setZip(e.target.value);
              }}
            />
            <input
              placeholder="Ville"
              className={
                errorPage1 && zip === null
                  ? "errorBorderInfosDate2"
                  : " inputDivInfosDate2"
              }
              value={city}
              type="text"
              onChange={(e) => {
                e.preventDefault();
                setCity(e.target.value);
              }}
            />
          </div>
          {infosResaIsOpen && (
            <input
              placeholder="Entrez votre Code postal"
              className="inputDivInfosDate2 marginTop"
              value={city !== null && city}
              type="text"
            />
          )}
          <div className="containerDateModal">
            <div className="textDate">Date</div>
            {!data ? (
              <input
                className={
                  errorPage1 && pickUpDate === null
                    ? "errorBorder"
                    : " inputDateInfos"
                }
                type="date"
                min={pickUpDate}
                defaultValue={pickUpDate}
                onChange={handleDate}
              />
            ) : (
              // VALUE RETURN FROM DATA ALREADY SAVED
              <input
                className={
                  errorPage1 && pickUpDate === null
                    ? "errorBorder"
                    : " inputDateInfos"
                }
                type="date"
                min={`${yearFromDataReturn}-${
                  monthFromDataReturn <= 9
                    ? `0${monthFromDataReturn}`
                    : monthFromDataReturn
                }-${
                  dayFromDataReturn <= 9
                    ? `0${dayFromDataReturn}`
                    : dayFromDataReturn
                }`}
                defaultValue={`${yearFromDataReturn}-${
                  monthFromDataReturn <= 9
                    ? `0${monthFromDataReturn}`
                    : monthFromDataReturn
                }-${
                  dayFromDataReturn <= 9
                    ? `0${dayFromDataReturn}`
                    : dayFromDataReturn
                }`}
                onChange={(e) => {
                  e.preventDefault();
                  setPickUpDate(e.target.value);
                }}
              />
            )}
          </div>
          <div className="containerDate">
            <div
              className={
                selected === 2
                  ? "containerSlotAvailableSelected"
                  : "containerSlotAvailable"
              }
              onClick={() => {
                setSlotsAvailableBeginning(8);
                setSlotsAvailableEnding(12);
                setSelected(2);
              }}
            >
              <div
                className={selected === 2 ? "textAlignWhite" : "textAlignBlack"}
              >
                Matin
              </div>
              <div className="textHour">8h00 - 12h00</div>
            </div>
            <div
              className={
                selected === 3
                  ? "containerSlotAvailableSelected"
                  : "containerSlotAvailable"
              }
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => {
                setSlotsAvailableBeginning(12);
                setSlotsAvailableEnding(18);
                setSelected(3);
              }}
            >
              <div
                className={selected === 3 ? "textAlignWhite" : "textAlignBlack"}
              >
                Après midi
              </div>
              <div className="textHour">12h00 - 17h00</div>
            </div>
            <div
              className={
                selected === 1
                  ? "containerSlotAvailableSelected"
                  : "containerSlotAvailable"
              }
              onClick={() => {
                setSlotsAvailableBeginning(8);
                setSlotsAvailableEnding(18);

                setSelected(1);
              }}
            >
              <div
                className={selected === 1 ? "textAlignWhite" : "textAlignBlack"}
              >
                La journée
              </div>
              <div className="textHour">8h00 - 17h00</div>
            </div>
          </div>
        </div>
        {infosResaIsOpen !== true && (
          <div>
            <div className="textReglementation">
              Cette version propose un service
            </div>
            <div className="textReglementationRed">
              exclusivement en ILE DE FRANCE
            </div>
          </div>
        )}
        {infosResaIsOpen !== true && (
          <BtnValidate
            title={!infosResaIsOpen ? "RÉSERVER MAINTENANT" : "MODIFIER"}
            handleNext={handleNext}
            processNumber={processNumber}
          />
        )}
      </div>
      {(errorPage1 || errorPage2 || errorPage3) && (
        <div className="errorMessage">Merci de tout remplir</div>
      )}
      {errorPassword && (
        <div className="errorMessage">Vos MDP ne sont pas similaires</div>
      )}
      {infosResaIsOpen !== true && (
        <div className="textBottomPrice">
          Le convoyage de votre véhicule à 39€ TTC
        </div>
      )}
    </div>
  );
}

export default InfosSlotsAvailable;
