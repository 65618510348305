import axios from "axios";
import React, { useState } from "react";
import BtnValidate from "../components/BtnValidate";
import InfosSlotsAvailable from "../components/InfosSlotsAvailable";
import InfosUser from "./InfosUser";
import InfosVehicle from "./InfosVehicle";
import "../styles/Account.css";
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from "@react-google-maps/api";

// ICONS
import userProfil from "../img/userProfil.png";
import carProfil from "../img/carProfil.png";
import historiqueProfil from "../img/historiqueProfil.png";
import plus from "../img/plus.png";

function Account({
  data,
  email,
  firstname,
  name,
  Adress,
  zip,
  city,
  phone,
  brand,
  model,
  type,
  pickUpDate,
  year,
  Kilometers,
  Fuel,
  registration,
  slotsAvailableBeginning,
  slotsAvailableEnding,
  selected,
  setEmail,
  setFirstname,
  setName,
  setAdress,
  setCity,
  setZip,
  setBrand,
  setModel,
  setType,
  setYear,
  setKilometers,
  setFuel,
  setPrice,
  setRegistration,
  setPickUpDate,
  setSlotsAvailableBeginning,
  setSlotsAvailableEnding,
  setPhone,
  setPassword,
  setPasswordConfirm,
  setSelected,
  setDataReturned,
  setToken,
  windowDimensions,
  setPage,
  handleNextAlreadyConnected,
}) {
  const [infosResaIsOpen, setInfosResaIsOpen] = useState(false);
  const [infosUserIsOpen, setInfosUserIsOpen] = useState(false);
  const [infosVehicleIsOpen, setInfosVehicleIsOpen] = useState(false);
  const [orderUpdated, setOrderUpdated] = useState(null);
  const [directionResponse, setDirectionResponse] = useState(null);

  const initialCenter = {
    lat: 48.859047,
    lng: 2.3567828,
  };
  const dayOfResa =
    data.orders.length >= 1
      ? new Date(
          orderUpdated === null
            ? data.orders[0].pickUpDate
            : orderUpdated.pickUpDate
        )
      : new Date();

  const dayOfDate = dayOfResa.getDate();
  const monthOfDate = dayOfResa.getMonth() + 1;
  const yearOfDate = dayOfResa.getFullYear();

  const formData = new FormData();
  // FOR UPDATE USER
  formData.append("firstname", firstname === null ? "" : firstname);
  formData.append("name", name === null ? "" : name);
  formData.append("phone", phone === null ? "" : phone);
  formData.append("email", email === null ? "" : email);
  // FOR UPDATE VEHICLE
  formData.append("brand", brand === null ? "" : brand);
  formData.append("model", model === null ? "" : model);
  formData.append("type", type === data.vehicles[0].type ? "" : type);
  formData.append("year", year === null ? "" : year);
  formData.append("kilometers", Kilometers === null ? "" : Kilometers);
  formData.append(
    "fuel",
    Fuel === data.vehicles[0].fuel ? data.vehicles[0].fuel : Fuel
  );
  formData.append("registration", registration === null ? "" : registration);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  async function calculateRoute() {
    if (data.orders.length >= 1) {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: data && data.orders[0].pickUpLocation,
        destination: data && data.orders[0].garageLocation,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionResponse(results);
    }
  }

  if (isLoaded) {
    calculateRoute();
  }

  // ONLY FOR UPDATE THE ORDER
  const handleUpdateOrder = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/update/order`,
      {
        id: data.orders[0]._id,
        indexStatus: 1,
        pickUpAdressUpdated: Adress !== null ? `${Adress} ${zip} ${city}` : "",
        newPickUpCity: city,
        newDateOfPickUp: pickUpDate,
        slotsAvailableBeginning: slotsAvailableBeginning,
        slotsAvailableEnding: slotsAvailableEnding,
      }
    );
    if (response) {
      setAdress(null);
      setPickUpDate(null);
      setSlotsAvailableBeginning(null);
      setSlotsAvailableEnding(null);
      setZip(null);
      setCity(null);
      setSelected(0);
      setInfosResaIsOpen(false);
      setOrderUpdated(response.data.orderUpdated);
    }
  };
  //  FOR UPDATE THE INFORMATIONS FROM USER AND VEHICLE
  const handleUpdateAccount = async (num) => {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/update/user`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + data && data.token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (num === 1) {
      setEmail(null);
      setFirstname(null);
      setName(null);
      setPhone(null);
      setPassword(null);
      setPasswordConfirm(null);
      setInfosUserIsOpen(false);
      if (response.data.message === "user updated") {
        setDataReturned(response.data.userFindedToUpdate);
      }
    } else {
      setBrand(null);
      setModel(null);
      setYear(null);
      setKilometers(null);
      setPrice(128);
      setRegistration(null);
      setInfosVehicleIsOpen(false);
      if (response.data.message === "user updated") {
        setDataReturned(response.data.userFindedToUpdate);
      }
    }
  };

  const carType = {
    car: "Citadine",
    "4x4": "4x4",
    carHybrid: "Hybrid ou électrique",
    van: "Utilitaire / Camping car",
    gpl: "Véhicule au Gaz",
  };

  return (
    <>
      {!infosUserIsOpen && !infosVehicleIsOpen ? (
        <div className="containerAccount">
          <div
            style={{
              height: 300,
              width: "100%",
              position: "absolute",
              top: 0,
            }}
          >
            <GoogleMap
              center={
                data && data.orders.length >= 1
                  ? data.orders[0].pickUpLocation
                  : initialCenter
              }
              zoom={12}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                zoomControl: false,
                streetViewControl: false,
              }}
            >
              {directionResponse !== null && (
                <DirectionsRenderer
                  directions={directionResponse !== null && directionResponse}
                />
              )}
            </GoogleMap>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                zIndex: 10,
                alignItems: "center",
                marginTop: 300,
              }}
            >
              <img src={userProfil} alt="user" className="iconAccount" />
              <div className="boxInfosAccount">
                <div className="titleBoxInfosAccount">DONNÉES PERSONNELLES</div>
                <div className="borderBottomAccount" />
                <div className="">
                  <div className="blocNameInfosAccount">
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Nom</div>
                      <div className="textNameInfosAccount">
                        {data && data.account.name}
                      </div>
                    </div>
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Prénom</div>
                      <div className="textNameInfosAccount">
                        {data && data.account.firstname}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="titleNameInfosAccount">E-mail</div>
                    <div className="textNameInfosAccount">
                      {data && data.email}
                    </div>
                  </div>
                  <div className="">
                    <div className="titleNameInfosAccount">Adresse</div>
                    <div className="textNameInfosAccount">
                      {data && data.account.adress}
                    </div>
                  </div>
                  <div className="blocNameInfosAccount">
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Code postal</div>
                      <div className="textNameInfosAccount">
                        {data && data.account.zip}
                      </div>
                    </div>
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Ville</div>
                      <div className="textNameInfosAccount">
                        {data && data.account.city.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btnAccount"
                    onClick={() => setInfosUserIsOpen(true)}
                  >
                    MODIFIER
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                zIndex: 10,
                alignItems: "center",
              }}
            >
              <img src={carProfil} alt="car" className="iconAccount" />
              <div className="boxInfosAccount">
                <div className="titleBoxInfosAccount">MON VÉHICULE</div>
                <div className="borderBottomAccount" />
                <div>
                  <div className="blocNameInfosAccount">
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Marque</div>
                      <div className="textNameInfosAccount">
                        {data && data.vehicles[0].brand}
                      </div>
                    </div>
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Modèle</div>
                      <div className="textNameInfosAccount">
                        {data && data.vehicles[0].model}
                      </div>
                    </div>
                  </div>
                  <div className="blocNameInfosAccount">
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Type</div>
                      <div className="textNameInfosAccount">
                        {data && carType[data.vehicles[0].type]}
                      </div>
                    </div>
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">
                        Immatriculation
                      </div>
                      <div className="textNameInfosAccount">
                        {data && data.vehicles[0].registration}
                      </div>
                    </div>
                  </div>
                  <div className="blocNameInfosAccount">
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Kilométrage</div>
                      <div className="textNameInfosAccount">
                        {data && data.vehicles[0].kilometers} km
                      </div>
                    </div>
                    <div className="divNameInfosAccount">
                      <div className="titleNameInfosAccount">Carburant</div>
                      <div className="textNameInfosAccount">
                        {data && data.vehicles[0].fuel}
                      </div>
                    </div>
                  </div>
                </div>
                {/* MAYBE THEY WOULD TO INCLUDE AGAIN THIS PART */}
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btnAccount"
                    onClick={() => setInfosVehicleIsOpen(true)}
                  >
                    MODIFIER
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="displaySmallScreen">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                zIndex: 10,
                alignItems: "center",
                marginTop: 300,
              }}
            >
              <img
                src={historiqueProfil}
                alt="historique"
                className="iconAccount"
              />
              <div className="boxInfosAccount">
                <div className="titleBoxInfosAccount">HISTORIQUE</div>
                <div className="borderBottomAccount" />
                <div>
                  {data !== null &&
                    data.ordersArchived.map((item, index) => {
                      const date = new Date(item.pickUpDate);
                      const day = date.getDate();
                      const month = date.getMonth() + 1;
                      const year = date.getFullYear();

                      return (
                        <div className="containerHistorique" key={index}>
                          <div className="titleNameInfosAccount">Véhicule</div>
                          <div className="textNameInfosAccount">
                            {data.vehicles[0].brand} {data.vehicles[0].model}
                          </div>
                          <div className="blocHistoriqueDate">
                            <div>
                              <div className="titleNameInfosAccount">Date</div>
                              <div className="textNameInfosAccount">
                                {day <= 9 ? `0${day}` : day}-
                                {month <= 9 ? `0${month}` : month}-{year}
                              </div>
                            </div>
                            <div style={{ marginRight: 10, marginLeft: 10 }}>
                              <div className="titleNameInfosAccount">Heure</div>
                              <div className="textNameInfosAccount">
                                {item.hourConfirmed}h00
                              </div>
                            </div>
                            <div>
                              <div className="titleNameInfosAccount">Prix</div>
                              <div className="textNameInfosAccount">
                                {item.price}€
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="containerBtnNewResa">
                <img src={plus} alt="plus" style={{ marginRight: 20 }} />
                <div
                  className="titleBtnNewResa"
                  onClick={() => handleNextAlreadyConnected(data)}
                >
                  NOUVELLE RÉSERVATION
                </div>
              </div>
              {data && data.orders[0] !== undefined && (
                <div
                  className={
                    windowDimensions.width >= 799
                      ? "boxInfosAccount"
                      : "blueBoxInfosAccount"
                  }
                >
                  <div className="titleBoxInfosAccount">VOTRE RENDEZ-VOUS</div>
                  <div
                    className={
                      windowDimensions.width >= 799
                        ? "borderBottomAccount"
                        : "blueBorderBottomAccount"
                    }
                  />

                  {data.orders.length >= 1 && (
                    <div className="containerBoxInfosAccount">
                      <div
                        className={
                          windowDimensions.width >= 799
                            ? "blocNameInfosAccount"
                            : "blueBlocNameInfosAccount"
                        }
                      >
                        <div className="divNameInfosAccount">
                          <div className="titleNameInfosAccount">Vehicule</div>
                          <div className="textNameInfosAccount">
                            {data && data.vehicles[0].brand}{" "}
                            {data && data.vehicles[0].model}
                          </div>
                        </div>
                        <div className="divNameInfosAccount">
                          <div className="titleNameInfosAccount">Statut</div>
                          <div className="textNameInfosAccount">
                            {data && data.orders[0].status}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          windowDimensions.width >= 799
                            ? "blocAppointmentInfosAccount"
                            : "blueBlocAppointmentInfosAccount"
                        }
                      >
                        <div>
                          <div className="titleNameInfosAccount">Date</div>
                          <div className="textNameInfosAccount">
                            {dayOfDate <= 9 ? `0${dayOfDate}` : dayOfDate}-
                            {monthOfDate <= 9 ? `0${monthOfDate}` : monthOfDate}
                            -{yearOfDate}
                          </div>
                        </div>
                        <div>
                          <div className="titleNameInfosAccount">Heure</div>
                          <div className="textNameInfosAccount">
                            {data && data.orders[0].hourConfirmed === 0
                              ? "À confirmer"
                              : `${data && data.orders[0].hourConfirmed}h00`}
                          </div>
                        </div>
                        <div>
                          <div className="titleNameInfosAccount">Prix</div>
                          <div className="textNameInfosAccount">
                            {orderUpdated === null
                              ? data && data.orders[0].price
                              : orderUpdated.price}
                            €
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className={
                            windowDimensions.width >= 799
                              ? "btnAccount"
                              : "blueBtnAccount"
                          }
                          onClick={() => setInfosResaIsOpen(true)}
                        >
                          MODIFIER
                        </button>
                      </div>
                    </div>
                  )}

                  {infosResaIsOpen && (
                    <InfosSlotsAvailable
                      infosResaIsOpen={infosResaIsOpen}
                      handleNext={handleUpdateOrder}
                      data={data && data}
                      Adress={Adress}
                      setAdress={setAdress}
                      zip={zip}
                      selected={selected}
                      setSelected={setSelected}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      setSlotsAvailableBeginning={setSlotsAvailableBeginning}
                      setSlotsAvailableEnding={setSlotsAvailableEnding}
                      city={city}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="containerBtnDisconnect">
            <button className="btnAccountDisconnect" onClick={handleDisconnect}>
              Deconnexion
            </button>
          </div> */}
        </div>
      ) : (
        <>
          <div className="boxInfosAccount">
            {infosUserIsOpen === true && (
              <InfosUser
                infosUserIsOpen={infosUserIsOpen}
                data={{ email: data.email, account: data.account }}
                setEmail={setEmail}
                setFirstname={setFirstname}
                setName={setName}
                setPhone={setPhone}
                setPassword={setPassword}
                setPasswordConfirm={setPasswordConfirm}
              />
            )}
            {infosVehicleIsOpen === true && (
              <InfosVehicle
                data={data}
                infosVehicleIsOpen={infosVehicleIsOpen}
                setBrand={setBrand}
                setModel={setModel}
                setType={setType}
                setYear={setYear}
                setKilometers={setKilometers}
                setFuel={setFuel}
                setPrice={setPrice}
                setRegistration={setRegistration}
              />
            )}
          </div>

          <div
            className="blocBtnContinu"
            onClick={() => setInfosUserIsOpen(false)}
          >
            <BtnValidate
              title={"Modifier"}
              handleNext={
                infosUserIsOpen
                  ? () => {
                      handleUpdateAccount(1);
                      setInfosUserIsOpen(false);
                    }
                  : () => {
                      handleUpdateAccount(2);
                      setInfosResaIsOpen(false);
                    }
              }
            />
          </div>
        </>
      )}
    </>
  );
}

export default Account;
